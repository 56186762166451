export const ROOT = '/o/:organizationId'
export const DATA_COLLECTION_ROUTE = `${ROOT}/dataCollection`
export const CONNECT_ROUTE = `${ROOT}/connect`
export const IMPORTS_ROUTE = `${ROOT}/imports_v2`
export const MAPPINGS_ROUTE = `${ROOT}/mappings`

export const CAMPAIGNS_ROUTE = `${ROOT}/campaigns`
export const QUESTIONNAIRES_ROUTE = `${ROOT}/questionnaires`
export const MONITORING_ROUTE = `${ROOT}/monitoring`

export const ACTION_TEMPLATES_ROUTE = `${ROOT}/actionTemplates`
export const TRAJECTORY_ROUTE = `${ROOT}/trajectory`
export const ACTION_PLANS_ROUTE = `${ROOT}/actionPlans`
export const USER_ROUTE = `${ROOT}/settings/users/:authUserId`
export const ORGANIZATION_ROUTE = `${ROOT}/organization`
export const AUDIT_ROUTE = `${ROOT}/settings/audit`
export const SUPPLY_CHAIN_ROUTE = `${ROOT}/supplyChain/:tabKey`
export const DATA_ORIGINS_ROUTE = `${ROOT}/settings/dataOrigins`
export const CATEOGORIES_ROUTE = `${ROOT}/categories`
export const EMISSION_FACTORS_ROUTE = `${ROOT}/settings/emissionsFactors`
export const DATA_POINT_TAGS_ROUTE = `${ROOT}/settings/dataPointTags`
export const METRICS_ROUTE = `${ROOT}/settings/metrics`
export const TEAM_ROUTE = `${ROOT}/settings/team`
export const WELCOME_PAGE_ROUTE = `${ROOT}/settings/welcome-page`
export const ROLES_ROUTE = `${ROOT}/settings/roles`
export const CONTRIBUTION_PROJECTS_CATALOG_ROUTE = `${ROOT}/contributionProjectsCatalog`

export const ORGANIZATION_ROUTE_NEXT = `${ROOT}/settings/organization/*`
export const DATA_MANAGEMENT_ROUTE_NEXT = `${ROOT}/settings/data-management/*`
export const TEAM_ROUTE_NEXT = `${ROOT}/settings/team/*`

export const MEASURE_MENU = 'MEASURE_MENU'
export const MY_TASKS_MENU = 'MY_TASKS_MENU'
export const REDUCE_MENU = 'REDUCE_MENU'
export const SETTINGS_MENU = 'SETTINGS_MENU'
export const NET_ZERO_MENU = 'NET_ZERO'
export const ADMIN_MENU = 'ADMIN_MENU'
export const ANALYZE_MENU = 'ANALYZE_MENU'
export const LCA_MENU = 'LCA_MENU'
export const REPORTING_MENU = 'REPORTING_MENU'
export const CUSTOM_LINK_MENU = 'CUSTOM_LINK_MENU'
