import { type PropsWithChildren, useEffect, useMemo } from 'react'
import { type IToggle, useFlags } from '@unleash/proxy-client-react'
import { useParams } from 'react-router'
import { useStateWithSessionStorage } from 'src/common/hooks'
import { UnleashOverrideContext } from 'src/services/KeyShortcuts/Shortcuts/UnleashOverrideContext'

export const UnleashOverrideFlagProvider = ({ children }: PropsWithChildren) => {
  const actualFlags = useFlags()
  const { organizationId } = useParams<'organizationId'>()
  const [flags, setFlags] = useStateWithSessionStorage<IToggle[]>(`unleash-flags-${organizationId}`, actualFlags)

  useEffect(() => {
    setFlags(actualFlags)
  }, [JSON.stringify(actualFlags)])

  const context = useMemo(
    () => ({
      flags,
      setFlag: (flagName: string, isEnabled: boolean) => {
        const newFlags = flags?.map(flag => (flag.name === flagName ? { ...flag, enabled: isEnabled } : flag)) ?? []
        setFlags(newFlags)
      }
    }),
    [flags, setFlags]
  )

  return <UnleashOverrideContext.Provider value={context}>{children}</UnleashOverrideContext.Provider>
}
