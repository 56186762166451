import { FileDoneOutlined } from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/KeyShortcuts/Shortcuts/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { REPORTING_MENU } from 'src/navigation/routes'

export function useReportingMenu() {
  const isReportingModuleEnabled = useOverridableFlag(UnleashFlag.ReportingModule)
  const { t } = useTypedTranslation()

  if (!isReportingModuleEnabled) {
    return null
  }

  return {
    key: REPORTING_MENU,
    icon: <FileDoneOutlined />,
    label: <OrganizationLink to="/reporting">{t('menu.reporting')}</OrganizationLink>
  }
}
