import { colors as traaceColors, images as traaceImages } from 'src/app/theme/traace'
import { colors as tennaxiaColors, images as tenaxiaImages } from 'src/app/theme/tennaxia'
import { useFlag } from '@unleash/proxy-client-react'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'

export function useTheme() {
  const tennaxiaThemeActive = useFlag(UnleashFlag.TennaxiaTheme)
  return {
    colors: tennaxiaThemeActive ? tennaxiaColors : traaceColors,
    images: tennaxiaThemeActive ? tenaxiaImages : traaceImages
  }
}
