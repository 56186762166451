export function useHelpLink(url: string) {
  return () => {
    const form = document.createElement('form')
    form.innerHTML = `<input type='hidden' name='authenticate_password' value='TraaceHelp'>`
    form.action = url
    form.method = 'POST'
    form.target = '_blank'
    form.rel = 'noopener noreferrer'
    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
  }
}
