import { message } from 'antd'
import isNil from 'lodash/fp/isNil'
import { createContext, type ReactNode, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { type CurrentUserConfigQuery, useCurrentUserConfigQuery } from 'src/generated/graphql/types'
import { UserInfos } from 'src/models/UserInfos'
import ActionPlanForecastChartProvider from 'src/provider/ActionPlanForecastChartProvider'
import { useTypedTranslation } from '../common/utils'
import CenteredLoader from '../common/utils/CenteredLoader'
import { PreferredLanguageLocalStorageKey } from '../common/utils/PreferredLanguageDetector'

type OrganizationConfig = {
  isTraaceAdmin: boolean
  organization: CurrentUserConfigQuery['currentUser']['organization']
  currentUser: UserInfos
  currentUserDefaultPerimeterScope: {
    contributingEntityIds: { id: string; name: string }[]
    organizationNodeIds: { id: string; name: string }[]
  }
}

export const OrganizationConfigContext = createContext<OrganizationConfig | undefined>(undefined)

type OrganizationConfigProviderProps = {
  readonly children: ReactNode
}

export function ConfigProvider({ children }: OrganizationConfigProviderProps) {
  const { t } = useTypedTranslation()
  const navigate = useNavigate()
  const { loading, error, data } = useCurrentUserConfigQuery({
    onError: apolloError => {
      for (const graphQLError of apolloError.graphQLErrors) {
        if (graphQLError.extensions?.code === 'FORBIDDEN') {
          console.error('User does not belong to the organization', apolloError)
          message.error(t('common.user_does_not_belong_to_organization'), 3.2)
          setTimeout(() => navigate('/'), 3000)
          return
        }
      }

      console.error(apolloError)
      message.error(t('common.error_message'))
    }
  })

  const contextValue = useMemo(() => {
    if (!data) {
      return undefined
    }

    const authUser = data.currentUser.authUser
    const organization = data.currentUser.organization

    if (authUser.language !== localStorage.getItem(PreferredLanguageLocalStorageKey)) {
      if (authUser.language) {
        localStorage.setItem(PreferredLanguageLocalStorageKey, authUser.language)
      } else {
        localStorage.removeItem(PreferredLanguageLocalStorageKey)
        localStorage.removeItem('i18nextLng') // Remove cached value
      }
      window.location.reload()
      return null
    }

    return {
      isTraaceAdmin: !isNil(authUser.adminRole),
      currentUser: new UserInfos(data.currentUser),
      organization,
      currentUserDefaultPerimeterScope: {
        contributingEntityIds: data.currentUser.defaultContributingEntitiesFilter ?? [],
        organizationNodeIds: data.currentUser.defaultOrganizationNodesFilter ?? []
      }
    }
  }, [data])

  if (loading) {
    return <CenteredLoader />
  }

  if (error || !contextValue) {
    return null
  }

  return (
    <OrganizationConfigContext.Provider value={contextValue}>
      <ActionPlanForecastChartProvider>{children}</ActionPlanForecastChartProvider>
    </OrganizationConfigContext.Provider>
  )
}
