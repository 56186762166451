import React, { useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getSelection, $isTextNode, type ElementFormatType, FORMAT_ELEMENT_COMMAND } from 'lexical'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { Button } from 'antd'
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined } from '@ant-design/icons'
import { useTheme } from 'src/app/theme/useTheme'

export function Alignment() {
  const [editor] = useLexicalComposerContext()
  const { colors } = useTheme()

  const changeDirection = (direction: 'left' | 'center' | 'right') => {
    setSelection(direction)
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, direction as ElementFormatType)
    editor.update(() => {
      const selection = $getSelection()
      if (!selection) return
      selection.getNodes().forEach(node => {
        if ($isTextNode(node)) {
          node.setStyle(direction)
        }
      })
    })
  }

  const [selection, setSelection] = useState<'left' | 'center' | 'right'>('left')
  return (
    <>
      <OnChangePlugin
        onChange={() => {
          editor.getEditorState().read(() => {
            const currentSelection = $getSelection()
            if (!currentSelection) return
            currentSelection.getNodes().forEach(node => {
              if ($isTextNode(node)) {
                if (node.getStyle() === 'left' || node.getStyle() === 'center' || node.getStyle() === 'right') {
                  setSelection(node.getStyle() as 'left' | 'center' | 'right')
                } else {
                  setSelection('left')
                }
              }
            })
          })
        }}
      />
      <Button
        type="text"
        aria-checked={selection === 'left'}
        size="small"
        icon={<AlignLeftOutlined style={{ color: selection === 'left' ? colors.primary : '' }} />}
        onClick={() => changeDirection('left')}
      />
      <Button
        type="text"
        aria-checked={selection === 'center'}
        size="small"
        icon={<AlignCenterOutlined style={{ color: selection === 'center' ? colors.primary : '' }} />}
        onClick={() => changeDirection('center')}
      />
      <Button
        type="text"
        aria-checked={selection === 'right'}
        size="small"
        icon={<AlignRightOutlined style={{ color: selection === 'right' ? colors.primary : '' }} />}
        onClick={() => changeDirection('right')}
      />
    </>
  )
}
