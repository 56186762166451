import { type ListUnitsQuery, useListUnitsQuery } from 'src/generated/graphql/types'
import type { Metric } from 'src/type'
import { useMemo } from 'react'

function displayUnit(unit?: { shortLabel: string }, unitAttribute?: string | null) {
  if (!unit) return ''
  return `${unit.shortLabel}${unitAttribute ? ` ${unitAttribute}` : ''}`
}

type UseUnitsOptions = {
  includeArchived?: boolean
  limit?: number
}

export function useUnits({ includeArchived = false, limit = 1000 }: UseUnitsOptions = {}) {
  const { data, loading, refetch } = useListUnitsQuery({ variables: { offset: 0, limit, includeArchived: true } })

  const getUnit = (id: string | undefined): undefined | ListUnitsQuery['listUnits']['results'][0] => {
    if (!id) return undefined
    return data?.listUnits.results.find(unit => unit.id === id)
  }

  const units = useMemo(() => {
    const fullList = data?.listUnits.results ?? []
    if (includeArchived) return fullList
    return fullList.filter(unit => !unit.isArchived)
  }, [data?.listUnits.results])

  return {
    loading,
    getUnit,
    displayUnit,
    getUnitsForMetric: (metric: Metric | undefined) => {
      if (!metric) return []
      return units.filter(unit => unit.unitGroup?.id === metric?.unitGroup?.id)
    },
    getUnitsForUnitGroupId: (unitGroupId: string | undefined) => {
      if (!unitGroupId) return []
      return units.filter(unit => unit.unitGroup?.id === unitGroupId && !unit.isArchived)
    },
    getGHGTonsUnit: () => data?.listUnits.results.find(unit => unit.id === 'TONS'),
    units,
    refetch
  }
}
