import { Checkbox } from 'antd'
import { useState } from 'react'
import type { LCAPreset } from 'src/modules/lcas/templateDetails/presets/model/LCAPreset'

export function usePresetFilter(presets?: LCAPreset[]) {
  const [hiddenComponentIds, setHiddenComponentIds] = useState<Set<string>>(new Set())
  if (!presets) {
    return {
      presets: undefined,
      filterComponent: null
    }
  }

  const filteredPresets = [...presets]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(preset => ({
      ...preset,
      resolvedComponents: preset.resolvedComponents.filter(rc => !hiddenComponentIds.has(rc.variant.impactComponent.id))
    }))

  const components = presets.flatMap(preset => preset.resolvedComponents.flatMap(rc => rc.variant.impactComponent))
  const uniqueComponents = (
    [...new Set(components.map(c => c.id))].map(id => components.find(c => c.id === id)) as NonNullable<
      (typeof components)[0]
    >[]
  ).sort((a, b) => {
    return a.name.localeCompare(b.name)
  })

  const component = (
    <div className="flex flex-wrap gap-4">
      {uniqueComponents.map(uniqueComponent => (
        <div key={uniqueComponent.id} className="flex flex-row gap-1 items-center">
          <Checkbox
            checked={!hiddenComponentIds.has(uniqueComponent.id)}
            onChange={event => {
              const newHiddenComponentIds = new Set(hiddenComponentIds)
              if (event.target.checked) {
                newHiddenComponentIds.delete(uniqueComponent.id)
              } else {
                newHiddenComponentIds.add(uniqueComponent.id)
              }
              setHiddenComponentIds(newHiddenComponentIds)
            }}
          />
          <div style={{ backgroundColor: uniqueComponent.color, width: '16px', height: '16px' }} className="rounded" />
          <div>{uniqueComponent.name}</div>
        </div>
      ))}
    </div>
  )

  return {
    presets: filteredPresets,
    filterComponent: component
  }
}
