import { SwapOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { Link } from 'react-router'
import { useAvailableOrganizationsQuery } from 'src/generated/graphql/types'
import { useTypedTranslation } from 'src/common/utils/translation-codegen'

type OrganizationButtonProps = Readonly<{ organizationName: string; compactMode?: boolean }>

export function OrganizationButton({ organizationName, compactMode }: OrganizationButtonProps) {
  const { data } = useAvailableOrganizationsQuery()
  const { t } = useTypedTranslation()

  return (
    <div className="flex flex-row gap-2">
      <div>{organizationName}</div>
      {data && data.listAvailableOrganizations.length > 1 && (
        <Link to="/">
          {compactMode ? (
            <SwapOutlined className="border border-solid rounded p-px hover:opacity-60" />
          ) : (
            <Button size="small">{t('user_details.switch_organization_button_title')}</Button>
          )}
        </Link>
      )}
    </div>
  )
}
