import { type AppState, Auth0Provider } from '@auth0/auth0-react'
import React, { type ReactNode } from 'react'
import { useNavigate } from 'react-router'

export default function Auth0ProviderWithHistory(props: { readonly children: ReactNode }) {
  const navigate = useNavigate()

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN ?? ''}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID ?? ''}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      {props.children}
    </Auth0Provider>
  )
}
