import React, { type PropsWithChildren, useEffect } from 'react'
import { setTwoToneColor } from '@ant-design/icons'
import { ConfigProvider } from 'antd'
import antEnLocale from 'antd/locale/en_GB'
import type { Locale } from 'antd/lib/locale'
import antFrLocale from 'antd/locale/fr_FR'
import antDeLocale from 'antd/locale/de_DE'
import { useTypedTranslation } from 'src/common/utils'
import { appTheme } from 'src/app/theme/theme'
import kebabCase from 'lodash/fp/kebabCase'
import Color from 'color'
import type { ThemeColorsType } from 'src/app/theme/type'

const antLocales: { [key: string]: Locale } = {
  fr: antFrLocale,
  de: antDeLocale,
  en: antEnLocale
}

type GenericAntConfigProviderProps = PropsWithChildren & Readonly<{ colors: ThemeColorsType }>

export function AntConfigProvider({ children, colors }: GenericAntConfigProviderProps) {
  const { i18n } = useTypedTranslation()

  useEffect(() => {
    setTwoToneColor(colors.lightgreenBase)
    const root = document.documentElement
    Object.keys(colors).forEach(key => {
      const rgbColor = Color(colors[key as keyof ThemeColorsType])
        .rgb()
        .array()
      root.style.setProperty(`--color-${kebabCase(key)}`, rgbColor.join(' '))
    })
  }, [])

  return (
    <ConfigProvider locale={antLocales[i18n.language] ?? antEnLocale} theme={appTheme(colors)}>
      {children}
    </ConfigProvider>
  )
}
