import { DeleteOutlined, DeleteTwoTone } from '@ant-design/icons'
import { Button, Popconfirm, Tooltip } from 'antd'
import type { RenderFunction } from 'antd/lib/_util/getRenderPropValue'
import type { SizeType } from 'antd/lib/config-provider/SizeContext'
import type { TooltipPlacement } from 'antd/lib/tooltip'
import React from 'react'
import { useTypedTranslation } from 'src/common/utils'

type DeleteButtonProps = Readonly<{
  confirmTitle: React.ReactNode | RenderFunction
  tooltipTitle?: string
  onConfirm: (e?: React.MouseEvent<HTMLElement>) => void | Promise<void>
  disabled?: boolean
  placement?: TooltipPlacement
  size?: SizeType
  okText?: React.ReactNode
  cancelText?: React.ReactNode
  version?: 'icon' | 'text'
}>

function DeleteButton({
  confirmTitle,
  tooltipTitle,
  onConfirm,
  disabled = false,
  placement = 'rightBottom',
  size = 'middle',
  cancelText,
  okText,
  version = 'icon'
}: DeleteButtonProps) {
  const { t } = useTypedTranslation()

  // It seems that Popconfirm does not work if we put the condition in its children jsx so I duplicated the whole block
  if (version === 'icon')
    return (
      <Popconfirm
        disabled={disabled}
        placement={placement}
        title={confirmTitle}
        onConfirm={e => {
          e?.stopPropagation()
          onConfirm(e)
        }}
        onCancel={e => e?.stopPropagation()}
        okText={okText || t('common.delete_button')}
        okType="danger"
        cancelText={cancelText || t('common.cancel_button_title')}
      >
        <Tooltip title={tooltipTitle || t('common.delete')} placement="bottomLeft">
          <Button
            shape="circle"
            onClick={e => e.stopPropagation()}
            aria-label={tooltipTitle || t('common.delete')}
            icon={disabled ? <DeleteOutlined /> : <DeleteTwoTone />}
            disabled={disabled}
            size={size}
          />
        </Tooltip>
      </Popconfirm>
    )

  return (
    <Popconfirm
      disabled={disabled}
      placement={placement}
      title={confirmTitle}
      onConfirm={e => {
        e?.stopPropagation()
        onConfirm(e)
      }}
      onCancel={e => e?.stopPropagation()}
      okText={okText || t('common.delete_button')}
      okType="danger"
      cancelText={cancelText || t('common.cancel_button_title')}
    >
      <Button onClick={e => e.stopPropagation()} disabled={disabled} size={size}>
        {t('common.delete')}
      </Button>
    </Popconfirm>
  )
}

export default DeleteButton
