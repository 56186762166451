import React, { useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { $getSelection, $isTextNode, FORMAT_TEXT_COMMAND } from 'lexical'
import { Button } from 'antd'
import { StrikethroughOutlined } from '@ant-design/icons'
import { useTheme } from 'src/app/theme/useTheme'

export const Strikethrough = () => {
  const [isSelectionStrikethrough, setSelectionStrikethrough] = useState(false)
  const { colors } = useTheme()
  const [editor] = useLexicalComposerContext()

  const onChange = () => {
    editor.getEditorState().read(() => {
      const selection = $getSelection()
      if (!selection) return
      const hasNodeItalicFormat = selection
        .getNodes()
        .map(node => $isTextNode(node) && node.hasFormat('strikethrough'))
        .some(Boolean)
      setSelectionStrikethrough(hasNodeItalicFormat)
    })
  }
  return (
    <>
      <Button
        title="Strikethrough"
        icon={<StrikethroughOutlined style={{ color: isSelectionStrikethrough ? colors.primary : '' }} />}
        type="text"
        size="small"
        aria-checked={isSelectionStrikethrough}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
        }}
      />
      <OnChangePlugin onChange={onChange} />
    </>
  )
}
