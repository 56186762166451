import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router'

/**
 * 2 hooks :
 * Hook to manage a state with a value stored in the URL
 * Hook to manage a state with an array stored in the URL
 */

export function encodeParam(param: string | string[] | undefined): string | undefined {
  if (Array.isArray(param)) {
    return param.length === 1 ? `${param[0]}` : `[${param.join(',')}]`
  }
  return param
}

export function decodeParam(param: string | undefined): string | string[] | undefined {
  if (param === undefined || param === '') return param

  if (param.startsWith('[') && param.endsWith(']')) {
    const innerContent = param.slice(1, -1)
    if (innerContent === '') {
      return []
    }
    return innerContent.split(',')
  }
  return [param]
}

export function useArrayStateWithURLStorage(key?: string, initialValue?: string[]) {
  const [urlStateValue, setURLStateValue] = useState<string[] | undefined | null>()
  const [searchParams, setSearchParams] = useSearchParams()
  const setURLValue = (values?: string[] | null) => {
    if (!key) {
      return
    }

    setSearchParams(
      prevParams => {
        if (!values || (values.length === 0 && initialValue && initialValue.length !== 0)) {
          prevParams.delete(key)
          setURLStateValue(null)
        } else {
          setURLStateValue(values)
          const stringValue = values.length !== 1 ? encodeParam(values) : encodeParam(values[0])
          prevParams.set(key, stringValue as string)
        }
        return prevParams
      },
      {
        replace: true
      }
    )
  }

  useEffect(() => {
    if (!key) {
      return
    }
    if (initialValue && !searchParams.get(key)) {
      setURLValue(initialValue)
    }
  }, [])

  useEffect(() => {
    if (!key) {
      return
    }
    const val = decodeParam(searchParams.get(key) ?? undefined)
    setURLStateValue(!Array.isArray(val) && val ? [val] : (val as string[] | undefined | null))
  }, [searchParams])

  return [urlStateValue, setURLValue] as [string[] | undefined, (values?: string[] | null) => void]
}

export function useStateWithURLStorage(key: string, initialValue?: string) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [storedValue, setStoredValue] = useState<string | undefined>(() => searchParams.get(key) ?? undefined)

  const setValue = (value?: string | null) => {
    setSearchParams(
      prevParams => {
        if (!value) {
          prevParams.delete(key)
          setStoredValue(undefined)
        } else {
          prevParams.set(key, value)
          setStoredValue(value)
        }
        return prevParams
      },
      {
        replace: true // replace the current history entry so that the back button doesn't go back to the previous state but to the previous page
      }
    )
  }

  const resetValue = () => {
    searchParams.delete(key)
  }

  useEffect(() => {
    if (initialValue && !searchParams.get(key)) {
      setValue(initialValue)
    }
  }, [])
  useEffect(() => {
    setStoredValue(searchParams.get(key) ?? undefined)
  }, [searchParams])

  return [storedValue, setValue, resetValue] as [string | undefined, (value?: string | null) => void, () => void]
}
