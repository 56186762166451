import { type ReactNode, createContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { usePagination } from 'src/common/utils/pagination'

const ConnectDefaultValues = {
  currentPage: 1,
  setCurrentPage: () => undefined,
  offset: 0,
  limit: 10
}

type ConnectContextProps = {
  currentPage: number | undefined
  setCurrentPage: (pageNumber: number) => void
  offset: number | undefined
  limit: number
}

export const ConnectContext = createContext<ConnectContextProps>({
  currentPage: ConnectDefaultValues.currentPage,
  setCurrentPage: ConnectDefaultValues.setCurrentPage,
  offset: ConnectDefaultValues.offset,
  limit: ConnectDefaultValues.limit
})

export const ConnectContextProvider = ({ children }: { readonly children: ReactNode }) => {
  const { organizationId } = useParams<'organizationId'>()

  useEffect(() => {
    return () => {
      sessionStorage.removeItem(`/o/${organizationId}/connectlimit`)
      sessionStorage.removeItem(`/o/${organizationId}/connect/offset`)
    }
  }, [])
  const { currentPage, setCurrentPage, offset, limit } = usePagination()

  const values = useMemo(() => {
    return { currentPage, setCurrentPage, offset, limit }
  }, [currentPage, setCurrentPage])

  return <ConnectContext.Provider value={values}>{children}</ConnectContext.Provider>
}
