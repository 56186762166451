// A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`.
//
import { ContributingDataPointSourceType, type ListMetricsQuery, type UnitEntity } from 'src/generated/graphql/types'
import type { I18nKey } from 'src/generated/i18n-types'

export type LocalDate = string

export type ErrorsType = {
  message: string | string[]
  statusCode: number
  validationErrors: { code: string; description: string; line: number }[]
}

export type ServerError = {
  errorMessage: string
}

export type Metric = ListMetricsQuery['listMetrics']['results'][0]
export type Unit = Pick<UnitEntity, 'id' | 'label' | 'shortLabel' | 'conversionFactor'>

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export const POINT_SOURCE_TYPE_OPTIONS: Record<ContributingDataPointSourceType, I18nKey> = {
  [ContributingDataPointSourceType.ConnectImport]:
    'dashboards.custom.broken.contributing_data_point_source_type.CONNECT_IMPORT',
  [ContributingDataPointSourceType.CampaignFlow]:
    'dashboards.custom.broken.contributing_data_point_source_type.CAMPAIGN_FLOW',
  [ContributingDataPointSourceType.ManualEntry]:
    'dashboards.custom.broken.contributing_data_point_source_type.MANUAL_ENTRY',
  [ContributingDataPointSourceType.Unknown]: 'dashboards.custom.broken.contributing_data_point_source_type.UNKNOWN',
  [ContributingDataPointSourceType.FileImport]:
    'dashboards.custom.broken.contributing_data_point_source_type.FILE_IMPORT',
  [ContributingDataPointSourceType.Extrapolation]:
    'dashboards.custom.broken.contributing_data_point_source_type.EXTRAPOLATION'
}
