import { ControlOutlined } from '@ant-design/icons'
import { Link } from 'react-router'
import { useOrganizationConfig } from 'src/provider'
import { ADMIN_MENU } from '../routes'

function useAdminMenu() {
  const { isTraaceAdmin } = useOrganizationConfig()

  if (!isTraaceAdmin) {
    return null
  }

  return {
    key: ADMIN_MENU,
    icon: <ControlOutlined />,
    label: <Link to="/admin">Admin</Link>
  }
}

export default useAdminMenu
