import { useCallback, useState } from 'react'

export function useStateWithSessionStorage<T>(key?: string, initialValue?: T) {
  const [storedValue, setStoredValue] = useState<T | null>(() => {
    const initialStoredValue = key ? parseValueFromSessionStorage<T>(key) : null
    return initialStoredValue ?? initialValue ?? null
  })

  const setValue = useCallback(
    (value: T | null) => {
      if (!key) {
        return
      }
      if (value !== null) {
        storeValueInSessionStorage(key, value)
        setStoredValue(value)
      } else {
        window.sessionStorage.removeItem(key)
        setStoredValue(null)
      }
    },
    [key, setStoredValue]
  )

  return [storedValue, setValue] as [T | null, (value: T | null) => void]
}

function storeValueInSessionStorage<T>(key: string, value: T): void {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  } catch {
    console.error('Failed to serialize value', value)
  }
}

function parseValueFromSessionStorage<T>(key: string): T | null {
  const serializedValue = window.sessionStorage.getItem(key)

  try {
    if (serializedValue !== null) {
      return JSON.parse(serializedValue) as T
    }
  } catch {
    console.error(`[key:${key}] Failed to deserialize value stored in sessionStorage`, serializedValue)
    window.sessionStorage.removeItem(key)
  }

  return null
}
