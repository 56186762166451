function hexToRGB(hex: string) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b]
}

export function generateRGBColorGradient(params: { hexReferenceColor: string, length: number }): string[] {
  const referenceColor = params.hexReferenceColor

  let startRGB: number[]
  if (referenceColor.startsWith('#')) {
    startRGB = hexToRGB(referenceColor)
  } else {
    startRGB = referenceColor.substring(4, referenceColor.length - 1)
      .replace(/ /g, '')
      .split(',')
      .map((c) => parseInt(c))
  }

  const colorDifference = 200
  const endRGB = [Math.min(startRGB[0] + colorDifference, 255), Math.min(startRGB[1] + colorDifference, 255), Math.min(startRGB[2] + colorDifference, 255)]

  const result: string[] = []
  for (let i = 0; i < params.length; i++) {
    const RGB = startRGB.map(function (component, index) {
      return Math.round(component + (endRGB[index] - component) * i / params.length);
    })
    result.push(`rgb(${RGB[0]},${RGB[1]},${RGB[2]})`)
  }
  return result
}