import { useFilterField, useStateWithSessionStorage } from 'src/common/hooks'
import { useLocation, useSearchParams } from 'react-router'
import isString from 'lodash/fp/isString'
import { SortOrder } from 'src/type'
import { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'

const DEFAULT_LIMIT = 10
const DEFAULT_OFFSET = 0

export const useSortOrder = (setCurrentPage: (pageNumber: number) => void) => {
  const pathName = window.location.pathname
  const [order, setOrder] = useFilterField<'ASC' | 'DESC'>({
    urlKey: 'order',
    defaultValue: 'ASC',
    type: 'string',
    sessionStorageKey: `${pathName}/order`
  })

  const [sort, setSort] = useFilterField<string>({
    urlKey: 'sort',
    type: 'string',
    sessionStorageKey: `${pathName}/sort`
  })

  return {
    order,
    setOrder: (sortOrder: 'ASC' | 'DESC') => {
      setOrder(sortOrder)
      setCurrentPage(1)
    },
    sort,
    setSort: (sortingKey: string) => {
      setSort(sortingKey)
      setCurrentPage(1)
    }
  }
}
export type Pagination = {
  offset: number
  currentPage?: number
  limit: number
  onChange: (newCurrent: number, newPageSize: number) => void
  handleRemoveItem: (dataLength: number | undefined) => void
  handleRemoveItems: (dataLength: number | undefined, removedItemsLength: number) => void
  handleFilterChange: () => void
  setCurrentPage: (pageNumber: number) => void
  checkCurrentPage: (totalCount: number) => void
}
export const usePagination = (params?: { offsetKey?: string; limit?: number; limitKey?: string }): Pagination => {
  const pathName = window.location.pathname

  const [storedOffset, setOffset] = useFilterField<number>({
    defaultValue: DEFAULT_OFFSET,
    type: 'number',
    urlKey: params?.offsetKey ?? 'offset',
    sessionStorageKey: params?.offsetKey ? `${pathName}/${params.offsetKey}` : `${pathName}/offset`
  })

  const [storedLimit, setLimit] = useFilterField<number>({
    defaultValue: params?.limit ?? DEFAULT_LIMIT,
    type: 'number',
    urlKey: params?.limitKey ?? 'limit',
    sessionStorageKey: params?.limitKey ? `${pathName}/${params.limitKey}` : `${pathName}/limit`
  })

  const limit = storedLimit ?? DEFAULT_LIMIT
  const offset = storedOffset ?? DEFAULT_OFFSET

  const currentPage = offset / limit + 1
  const setCurrentPage = (pageNumber: number) => {
    setOffset(Math.round((pageNumber - 1) * limit))
  }

  const onChange = (newCurrent: number, newPageSize: number) => {
    if (limit !== newPageSize) {
      setCurrentPage(1)
      setLimit(newPageSize)
    } else {
      setCurrentPage(newCurrent)
    }
  }

  //handle bulk delete
  const handleRemoveItems = (dataLength: number | undefined, removedItemsLength: number) => {
    if (currentPage && currentPage > 1 && dataLength === removedItemsLength) setCurrentPage(currentPage - 1)
  }

  //handle one item removal
  const handleRemoveItem = (dataLength: number | undefined) => {
    if (currentPage && currentPage > 1 && dataLength === 1) setCurrentPage(currentPage - 1)
  }

  const handleFilterChange = () => {
    setCurrentPage(1)
  }

  const checkCurrentPage = (totalCount: number) => {
    if (currentPage && totalCount <= limit * (currentPage - 1)) {
      const newCurrentPage = Math.trunc(Math.max((totalCount + limit - 1) / limit, 1))
      if (newCurrentPage !== currentPage) {
        setCurrentPage(newCurrentPage)
      }
    }
  }

  return {
    offset,
    currentPage,
    limit,
    onChange,
    handleRemoveItem,
    handleFilterChange,
    handleRemoveItems,
    setCurrentPage,
    checkCurrentPage
  }
}

export type BaseFilterParams<F, S extends string = ''> = {
  limit: number
  offset: number
  order: SortOrder
  sort?: S
  filters: F
}

type PaginationWithFiltersParams<F> = {
  key?: string
  defaultLimit?: number
  defaultFilters: F
}

export type PaginationWithFiltersReturn<F, S extends string = ''> = {
  params: BaseFilterParams<F, S>
  currentPage?: number
  handleSortChange: (sortOrder: BaseFilterParams<F, S>['order'], sortingKey: S) => void
  onChange: (newCurrent: number, newPageSize: number) => void
  onRemoveItems: (dataLength: number | undefined, removedItemsLength?: number) => void
  handleFilterChange: (filters: Partial<F>) => void
  checkCurrentPage: (totalCount: number) => void
}

export const getUrlParams = <F extends Record<string, string | string[] | null>, S extends string = ''>(
  newParams: BaseFilterParams<F, S>,
  prevParams = new URLSearchParams()
) => {
  prevParams.set('limit', String(newParams.limit))
  prevParams.set('offset', String(newParams.offset))
  prevParams.set('order', newParams.order)
  newParams.sort ? prevParams.set('sort', newParams.sort) : prevParams.delete('sort')
  return getUrlParamsWithFilters(newParams.filters, prevParams)
}

export const getUrlParamsWithFilters = (
  filters: Record<string, string | string[] | null | undefined>,
  prevParams = new URLSearchParams()
) => {
  Object.entries(filters).forEach(([filterKey, value]) => {
    prevParams.delete(`filters${filterKey}`)
    prevParams.delete(`filters${filterKey}[]`)
    if (Array.isArray(value)) {
      if (value.length === 0) prevParams.set(`filters${filterKey}[]`, '')
      else value.forEach(item => prevParams.append(`filters${filterKey}[]`, item))
    } else if (isString(value)) {
      prevParams.set(`filters${filterKey}`, value)
    } else prevParams.delete(`filters${filterKey}`)
  })
  return prevParams
}

export const usePaginationWithFilters = <F extends Record<string, string | string[] | null>, S extends string = ''>({
  key = 'filters',
  defaultLimit = DEFAULT_LIMIT,
  defaultFilters
}: PaginationWithFiltersParams<F>): PaginationWithFiltersReturn<F, S> => {
  const { pathname } = useLocation()

  const defaultParams: BaseFilterParams<F, S> = {
    offset: DEFAULT_OFFSET,
    limit: defaultLimit,
    sort: undefined,
    order: SortOrder.ASC,
    filters: defaultFilters
  }

  const [params, setSessionParams] = useStateWithSessionStorage<BaseFilterParams<F, S>>(
    `${pathname}/${key}`,
    defaultParams
  )
  const [searchParams, setSearchParams] = useSearchParams()

  const limit = params?.limit ?? defaultLimit
  const offset = params?.offset ?? DEFAULT_OFFSET
  const currentParams = params ?? defaultParams

  const currentPage = offset / limit + 1
  const getOffset = (pageNumber: number) => Math.round((pageNumber - 1) * limit)

  const setParams = (newParams: BaseFilterParams<F, S>) => {
    setSessionParams(newParams)
    setSearchParams(prevParams => getUrlParams(newParams, prevParams), { replace: true })
  }

  useEffect(() => {
    if (searchParams.size > 0) {
      const newParams = {
        offset: parseInt(searchParams.get('offset') ?? String(defaultParams.offset), 10),
        limit: parseInt(searchParams.get('limit') ?? String(defaultParams.limit), 10),
        order: (searchParams.get('order') as SortOrder | null) ?? defaultParams.order,
        sort: searchParams.get('sort') as S | undefined,
        filters: defaultParams.filters
      }
      searchParams.forEach((paramValue, paramKey) => {
        if (paramKey.startsWith('filter')) {
          // @ts-expect-error force set on filters of type T
          newParams.filters[paramKey.replace('filters', '').replace('[]', '')] = paramKey.endsWith('[]')
            ? searchParams.getAll(paramKey).filter(item => item !== '')
            : searchParams.get(paramKey)
        }
      })
      setSessionParams(newParams)
    } else if (params && !isEqual(params, defaultParams)) {
      setSearchParams(prevParams => getUrlParams(params, prevParams), { replace: true })
    }
  }, [pathname])

  return {
    params: currentParams,
    currentPage,
    handleSortChange: (sortOrder: BaseFilterParams<F, S>['order'], sortingKey: S) => {
      setParams({ ...currentParams, order: sortOrder, sort: sortingKey, offset: 0 })
    },
    onChange: (newCurrent: number, newPageSize: number) => {
      if (limit !== newPageSize) {
        setParams({ ...currentParams, limit: newPageSize, offset: 0 })
      } else {
        setParams({ ...currentParams, offset: getOffset(newCurrent) })
      }
    },
    handleFilterChange: (filters: Partial<F>) => {
      setParams({ ...currentParams, filters: { ...currentParams.filters, ...filters }, offset: 0 })
    },
    onRemoveItems: (dataLength: number | undefined, removedItemsLength: number = 1) => {
      if (currentPage && currentPage > 1 && dataLength === removedItemsLength)
        setParams({ ...currentParams, offset: getOffset(currentPage - 1) })
    },
    checkCurrentPage: (totalCount: number) => {
      if (currentPage && totalCount <= limit * (currentPage - 1)) {
        const newCurrentPage = Math.trunc(Math.max((totalCount + limit - 1) / limit, 1))
        if (newCurrentPage !== currentPage) {
          setParams({ ...currentParams, offset: getOffset(newCurrentPage) })
        }
      }
    }
  }
}

/**
 * Pagination hook that does not store the pagination state in the URL or session storage (useful for modals)
 */
export const useModalPagination = (params?: { limit?: number }): Pagination => {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(params?.limit ?? DEFAULT_LIMIT)
  const currentPage = offset / limit + 1
  const setCurrentPage = (pageNumber: number) => {
    setOffset(Math.round((pageNumber - 1) * limit))
  }
  return {
    offset,
    limit,
    currentPage,
    setCurrentPage,
    onChange: (newCurrent: number, newPageSize: number) => {
      if (limit !== newPageSize) {
        setOffset(0)
        setLimit(newPageSize)
      } else {
        setOffset(Math.round((newCurrent - 1) * limit))
      }
    },
    handleRemoveItem: (dataLength?: number) => {
      // if deleting the last item on the page, go back one page
      if (dataLength && offset > dataLength) {
        setCurrentPage(currentPage - 1)
      }
    },
    handleFilterChange: () => {
      setOffset(0)
    },
    handleRemoveItems: (dataLength: number | undefined, removedItemsLength: number) => {
      handleRemoveItems(dataLength, removedItemsLength, currentPage, setCurrentPage)
    },
    checkCurrentPage: (totalCount: number) => {
      checkCurrentPage(totalCount, currentPage, limit, setCurrentPage)
    }
  }
}

const handleRemoveItems: (
  dataLength: number | undefined,
  removedItemsLength: number,
  currentPage: number,
  setCurrentPage: (pageNumber: number) => void
) => void = (dataLength, removedItemsLength, currentPage, setCurrentPage) => {
  if (dataLength && removedItemsLength && dataLength === removedItemsLength) {
    setCurrentPage(currentPage - 1)
  }
}
const checkCurrentPage: (
  totalCount: number,
  currentPage: number,
  limit: number,
  setCurrentPage: (pageNumber: number) => void
) => void = (totalCount, currentPage, limit, setCurrentPage) => {
  if (currentPage && totalCount <= limit * (currentPage - 1)) {
    const newCurrentPage = Math.trunc(Math.max((totalCount + limit - 1) / limit, 1))
    if (newCurrentPage !== currentPage) {
      setCurrentPage(newCurrentPage)
    }
  }
}
