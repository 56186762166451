import type { ThemeColorsType } from 'src/app/theme/type'

export const appTheme = (colors: ThemeColorsType) => ({
  components: {
    Layout: { headerBg: colors.darkgreenBase },
    Menu: {
      itemBg: colors.darkgreenBase,
      subMenuItemBg: colors.primary,
      itemSelectedBg: colors.primary,
      itemActiveBg: colors.primary,
      itemColor: colors.greengrey10,
      itemHoverColor: colors.greengrey2,
      itemSelectedColor: colors.greengrey2
    },
    DatePicker: {
      controlHeight: 40
    },
    Table: {
      colorFillAlter: colors.background,
      colorLink: colors.darkgreenLighter1,
      colorLinkHover: colors.darkgreenLighter1,
      colorLinkActive: colors.darkgreenLighter1,
      headerBg: colors.background,
      headerSortActiveBg: colors.background
    },
    Drawer: {
      fontWeightStrong: 400
    },
    Button: {
      borderRadius: 0,
      borderRadiusSM: 0,
      controlOutlineWidth: 0,
      controlHeight: 40
    },
    Pagination: {
      controlHeight: 40
    },
    Tag: {
      borderRadiusSM: 100
    },
    Input: { controlHeight: 40 },
    InputNumber: { controlHeight: 40 },
    TreeSelect: { controlItemBgActive: colors.greengrey10 },
    Dropdown: { controlHeight: 40, controlItemBgActive: colors.greengrey10 },
    Select: { controlHeight: 40, controlItemBgActive: colors.greengrey10 },
    Progress: { colorInfo: colors.primary, colorSuccess: colors.primary }
  },
  token: {
    colorPrimary: colors.darkgreenLighter1,
    colorPrimaryBg: colors.backgroundLight,
    colorPrimaryBgHover: colors.backgroundLight,
    colorError: colors.error,
    colorWarning: colors.warning,
    colorInfo: colors.info,
    colorLink: colors.darkgreenLighter1,
    colorLinkHover: colors.darkgreenLighter1,
    colorLinkActive: colors.darkgreenLighter1,
    fontFamily: 'Inter, sans-serif',
    motionDurationFast: '0.075s',
    motionDurationMid: '0.15s',
    motionDurationSlow: '0.225s',
    colorInfoBg: colors.backgroundLight,
    colorInfoBorder: colors.greengrey30,
    borderRadius: 2
  }
})
