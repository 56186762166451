import React, { useEffect } from 'react'
import Auth0ProviderWithHistory from 'src/authentication/AuthProviderWithHistory'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import CenteredLoader from 'src/common/utils/CenteredLoader'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useTypedTranslation } from 'src/common/utils'
import { Route, Routes } from 'react-router'
import { MultiOrganizationsRoot } from 'src/app/multiOrganizations/MultiOrganizationsRoot'
import { Organization } from 'src/app/organization/Organization'

if (process.env.NODE_ENV === 'development') {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

declare global {
  interface Window {
    $crisp: any
    CRISP_WEBSITE_ID: string
    beamer_config: any
  }
}

dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(localizedFormat)

function App() {
  const { i18n } = useTypedTranslation()

  useEffect(() => {
    dayjs.locale(i18n.language)
  }, [i18n.language])

  return (
    <div className="App">
      <Routes>
        <Route path="o/:organizationId/*" element={<Organization />} />
        <Route path="*" element={<MultiOrganizationsRoot />} />
      </Routes>
    </div>
  )
}

const AppWithAuthentication = withAuthenticationRequired(App, {
  onRedirecting: () => <CenteredLoader />
})

function AuthentifiedApp() {
  return (
    <Auth0ProviderWithHistory>
      <AppWithAuthentication />
    </Auth0ProviderWithHistory>
  )
}

export default AuthentifiedApp
