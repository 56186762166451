import { useUnits } from 'src/common/hooks'
import { useNumberFormat } from 'src/common/utils'

type FootprintValueProps = {
  readonly valuePerReferenceUnit: number
  readonly unitEntity: {
    shortLabel: string
  }
  readonly referenceUnitLabel: string | undefined
  readonly referenceUnitAttribute?: string | null
  readonly align: 'start' | 'center' | 'end'
}

export function FootprintValue({
  valuePerReferenceUnit,
  unitEntity,
  referenceUnitLabel,
  referenceUnitAttribute,
  align
}: FootprintValueProps) {
  const { toNumber } = useNumberFormat()
  const { displayUnit } = useUnits()
  return (
    <div className={`flex flex-col shrink-0 items-${align}`}>
      <div className="font-medium text-lg">{toNumber(valuePerReferenceUnit, { maximumFractionDigits: 3 })}</div>
      <div className="text-xs">{`${referenceUnitLabel} / ${displayUnit(unitEntity, referenceUnitAttribute)}`}</div>
    </div>
  )
}
